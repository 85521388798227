export default {
  ADD_ITEM (state, item) {
    console.log('MUTATION ADD...')
    state.doctors.unshift(item)
  },
  SET_LIST (state, doctor) {
    console.log('MUTATION LIST...')
    state.doctors = doctor
  },
  SET_CENTRES (state, centres) {
    state.centres = centres
  },
  // SET_LABELS(state, labels) {
  //   state.eventLabels = labels
  // },
  UPDATE_ITEM (state, doctor) {
    console.log('MUTATION UPDATE...')
    const dataIndex = state.doctors.findIndex((p) => p.slug === doctor.slug);
    Object.assign(state.doctors[dataIndex], doctor)
  },
  REMOVE_ITEM (state, itemId) {
    console.log('MUTATION REMOVE...')
    const ItemIndex = state.doctors.findIndex((p) => p.slug === itemId);
    state.doctors.splice(ItemIndex, 1)
  }
}
