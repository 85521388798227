import state from './doctor.state.js'
import mutations from './doctor.mutations.js'
import actions from './doctor.actions.js'
import getters from './doctor.getters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

