<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <!-- SINGLE DATA -->
    <b-modal
      ref="singleDataModal"
      centered
      :title="'Fichier: ' + singleData.name"
      ok-only
      ok-title="Fermer"
    >
      <b-card-text>
        Réference:<b> {{ singleData.slug }}</b>
        <hr />
      </b-card-text>
      <b-card-text>
        Nom:<b> {{ singleData.name }}</b>
        <hr />
      </b-card-text>
      <b-card-text>
        Spécialité:<b> {{ singleData.speciality }}</b>
        <hr />
      </b-card-text>
      <b-card-text v-if="singleData.days">
        Jours de réception:<b> {{ singleData.days.join() }}</b>
      </b-card-text>
      <b-card-text v-if="singleData.hours">
        Horaires de réception:<b> {{ singleData.hours.join() }}</b>
        <hr />
      </b-card-text>
      <b-card-text>
        Prise de RDV:<b> {{ singleData.rdv }}</b>
        <hr />
      </b-card-text>
      <b-card-text v-if="singleData.centre">
        Infirmerie:<b> {{ singleData.centre.name }}</b>
        <hr />
      </b-card-text>
    </b-modal>
    <!-- NEW RECORD -->
    <b-modal
      id="saveModal"
      ref="saveModal"
      :title="
        Object.entries(this.modalData).length === 0 ? 'Nouveau' : 'Modification'
      "
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      centered
      hide-footer
      hide-header
    >
    <h3 class="">
      {{ Object.entries(this.modalData).length === 0 ? 'Nouveau' : 'Modification' }}
      <hr />
    </h3>
      <b-form @submit.prevent="save">
        <!-- NAME -->
        <b-form-group
          label="Nom"
          label-for="name"
          :state="formInputState.nameState"
        >
          <b-form-input
            v-model="formdata.dataName"
            id="name"
            placeholder="Dr Jordan Grébé"
            :state="formInputState.nameState"
            required
          />
        </b-form-group>
        <!-- SPECIALITY -->
        <b-form-group label="Spécialité" label-for="dataSpeciality">
          <v-select
            id="dataSpeciality"
            v-model="formdata.dataSpeciality"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            placeholder="Sélectionner spécialité"
            :options="formdata.speciality_choices"
          />
        </b-form-group>
        <!-- DAYS -->
        <b-form-group label="Jours de réception" label-for="dataDays">
          <v-select
            id="dataDays"
            v-model="formdata.dataDays"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            placeholder="Sélectionner jour(s)"
            :options="formdata.day_choices"
            multiple
          />
        </b-form-group>
        <!-- HOUR -->
        <b-row>
          <b-col md="6">
            <b-form-group label="Heure de réception" label-for="dataDays">
              <b-form-timepicker
                v-model="formdata.dataHour1"
                locale="fr"
                placeholder="Heure de début"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="." label-for="dataDays">
              <b-form-timepicker
                v-model="formdata.dataHour2"
                locale="fr"
                placeholder="Heure de fin"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- CENTRE -->
        <b-form-group label="Infirmerie" label-for="vue-select">
          <v-select
            id="vue-select"
            v-model="formdata.dataCentre"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            placeholder="Sélectionner"
            label="name"
            value="slug"
            :options="getCentre"
          />
        </b-form-group>
        <!-- IS RDV -->
        <b-form-group label="Prise de RDV" label-for="dataRDV">
          <b-form-input
            v-model="formdata.dataRDV"
            id="dataRDV"
            placeholder="Non"
          />
        </b-form-group>

        <!-- submit and reset -->
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              @click="isModalActiveLocal"
            >
              Fermer
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="ml-1"
              style="float: right"
              @click="save"
            >
              Enregistrer
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>

    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="pageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button variant="primary" @click="openSaveDialog">
            Nouveau
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="filter"
              class="d-inline-block mr-1"
              placeholder="Recherche..."
            />
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refInvoiceListTable"
      :items="buildData"
      responsive
      :fields="columns"
      primary-key="id"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      show-empty
      empty-text="Auncune donnée disponible"
      class="position-relative"
    >
      <template #head(invoiceStatus)>
        <feather-icon icon="TrendingUpIcon" class="mx-auto" />
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mr-1"
            @click.stop="openDialog(data.item)"
          />
          <b-tooltip
            title="Consulter"
            :target="`invoice-row-${data.item.slug}-preview-icon`"
          />

          <feather-icon
            :id="`invoice-row-${data.item.slug}-edit-icon`"
            icon="EditIcon"
            size="16"
            class="mr-1"
            @click.stop="editData(data.item)"
          />
          <b-tooltip
            title="Modifier"
            :target="`invoice-row-${data.item.slug}-edit-icon`"
          />

          <feather-icon
            :id="`invoice-row-${data.item.slug}-trash-icon`"
            icon="TrashIcon"
            size="16"
            class=""
            @click.stop="deleteRecordPrompt(data.item.slug)"
          />
          <b-tooltip
            title="Supprimer"
            :target="`invoice-row-${data.item.slug}-trash-icon`"
          />
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-start
          "
        >
          <span class="text-muted">Total: {{ buildData.length }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-end
          "
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BLink,
  BPagination,
  BTooltip,
  BModal,
  VBModal,
  BCardText,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTimepicker,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ModuleDoctor from "@/store/modules/medical/doctor/doctor.module";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { password, required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BPagination,
    BTooltip,
    BModal,
    BCardText,
    BForm,
    BFormGroup,

    vSelect,
    BFormTimepicker,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      singleData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Voir plus" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],

      columns: [
        { key: "name", label: "Nom", sortable: true },
        { key: "speciality", label: "Spécialité", sortable: true },
        {
          key: "centre",
          label: "Infirmerie",
          sortable: false,
          formatter: (val) => (val ? val.name : ""),
        },
        { key: "actions" },
      ],
      rows: [],
      searchTerm: "",
      locale: "fr",

      // Data NEW/EDIT Modal
      formdata: {
        dataId: null,
        //-FORM-PROPERTIES-
        dataName: "",
        dataSpeciality: "Médecine générale",
        dataHours: "",
        dataHour1: "",
        dataHour2: "",
        dataDays: "",
        dataRDV: "",
        dataCentre: "",
        speciality_choices: [
          "Médecine générale",
          "Pédiatrie",
          "Neuropathologie",
          "Psychiatrie",
          "Rhumatologie",
          "Biologie médicale",
          "Néonatologie",
          "Nutrition ",
          "Anatomie et cytologie pathologiques ",
          "Anesthésie-réanimation",
          "Cardiologie et maladies vasculaires",
          "Chirurgie générale",
          "Chirurgie orale",
          "Dermatologie et vénérologie",
          "Endocrinologie et métabolisme",
          "Gastro-entérologie et hépatologie",
          "Génétique médicale",
          "Gynécologie médicale",
          "Gynécologie obstétrique",
          "Hématologie",
          "Médecine nucléaire",
          "Neurochirurgie",
          "Oncologie",
        ],
        day_choices: [
          "LUNDI",
          "MARDI",
          "MERCREDI",
          "JEUDI",
          "VENDREDI",
          "SAMEDI",
          "DIMANCHE",
        ],
      },
      formInputState: {
        nameState: null,
      },
      isModalActive: false,
      modalData: [],
    };
  },
  created() {
    if (!ModuleDoctor.isRegistered) {
      this.$store.registerModule("ModuleDoctor", ModuleDoctor);
      ModuleDoctor.isRegistered = true;
    }
    this.$store.dispatch("ModuleDoctor/fetchData").catch((e) => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Erreur`,
            icon: "WarningIcon",
            variant: "danger",
            text: `Impossible de charger la liste des données. Merci de réessayer plutard.`,
          },
        });
    });
    this.$store.dispatch("ModuleDoctor/fetchCentre").catch((e) => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Erreur`,
            icon: "WarningIcon",
            variant: "danger",
            text: `Impossible de charger la liste des centres. Merci de réessayer plutard.`,
          },
        });
    });
  },
  computed: {
    buildData() {
      const data = this.$store.state.ModuleDoctor.doctors;
      this.totalRows = data === undefined ? 0 : data.length;

      return data === undefined ? [] : data;
    },
    getCentre() {
      return this.$store.state.ModuleDoctor.centres;
    },
  },
  
  methods: {
    isModalActiveLocal() {
      this.$refs['saveModal'].hide()
      this.modalData = []
      this.initValues()
    },
    openSaveDialog() {
      this.modalData = {};
      this.isModalActive = true;
      
      this.$refs['saveModal'].show()
    },
    editData(data) {
      this.modalData = data;
      this.isModalActive = true;

      if (!data) return;
      if (Object.entries(data).length === 0) {
        this.initValues();
        //this.$validator.reset();
      } else {
        let getHours;
        const { slug, name, speciality, days, hours, rdv, centre } = JSON.parse(
          JSON.stringify(data)
        );
        getHours = hours;
        this.formdata.dataId = slug;
        this.formdata.dataName = name;
        this.formdata.dataSpeciality = speciality;
        this.formdata.dataHours = hours.join();
        this.formdata.dataHour1 = getHours[0];
        this.formdata.dataHour2 = getHours[1];
        this.formdata.dataDays = days;
        this.formdata.dataRDV = rdv;
        this.formdata.dataCentre = centre;
        this.initValues();
      }

      this.$refs['saveModal'].show()
    },
    toggleDataModal(val = false) {
      this.addNewDataModal = val;
    },
    swalError(title, message, type) {
      this.$swal({
        title: title,
        text: message,
        icon: type,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    openLink(link) {
      window.open(link, "_blank");
    },
    openDialog(data) {
      this.$refs.singleDataModal.show();
      this.singleData = data;
    },

    /* ---------------------
      | RECORD REMOVAL HANDLER
       */
    deleteRecordPrompt(id) {
      this.$router.push({
        query: Object.assign({}, this.$route.query, { pid: id }),
      });
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("Voulez-vous supprimer cet enregistrement ?", {
          title: "Confirmation",
          size: "sm",
          okVariant: "danger",
          okTitle: "Oui",
          cancelTitle: "Non",
          cancelVariant: "outline-danger",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.deleteData();
          }
        });
    },
    deleteData() {
      let id = this.$route.query.pid;
      if (id !== "" || id !== null) {
        this.$store
          .dispatch("ModuleDoctor/shredItem", id)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Action Réussie`,
                icon: "CoffeeIcon",
                variant: "success",
                text: `Enregistrement supprimé avec succès!`,
              },
            });
            this.revertUrl();
          })
          .catch((error) => {
            if (typeof error === "object") {
              for (let err in error) {
                this.swalError("Erreur", err, "error");
              }
            } else {
              this.$vs.notify({
                title: "Erreur",
                text: error,
                color: "danger",
                icon: "error",
              });
            }
          });
      } else {
      }
    },
    revertUrl() {
      this.$router.replace({ name: "apps-medical-doctor" });
    },
    // END ---
    // MODAL FORM TOOL --------------
    initValues() {
      if (this.modalData.slug) return;
      this.formdata.dataId = null;
      this.formdata.dataName = "";
      this.formdata.dataSpeciality = "";
      this.formdata.dataHours = "";
      this.formdata.dataHour1 = "";
      this.formdata.dataHour2 = "";
      this.formdata.dataDays = [];
      this.formdata.dataRDV = "";
      this.formdata.dataCentre = "";
    },
    save(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      if (this.formdata.dataName === "") {
        this.swalError("Erreur", this.buildRequiredMessage("Nom"), "error");
      } else if (this.formdata.dataSpeciality === "") {
        this.swalError(
          "Erreur",
          this.buildRequiredMessage("Spécialité"),
          "error"
        );
      } else if (this.formdata.dataDays.length <= 0) {
        this.swalError(
          "Erreur",
          this.buildRequiredMessage("Jours de reception"),
          "error"
        );
      } else if (this.formdata.dataHour1 === "") {
        this.swalError(
          "Erreur",
          this.buildRequiredMessage("Heure de début"),
          "error"
        );
      } else if (this.formdata.dataHour2 === "") {
        this.swalError(
          "Erreur",
          this.buildRequiredMessage("Heure de fin"),
          "error"
        );
      } else {
        this.formdata.dataHours = [
          this.formdata.dataHour1,
          this.formdata.dataHour2,
        ];
        const obj = {
          slug: this.formdata.dataId,
          name: this.formdata.dataName,
          speciality: this.formdata.dataSpeciality,
          hours:
            typeof this.formdata.dataHours === "string"
              ? this.formdata.dataHours.split(",")
              : this.formdata.dataHours,
          days: this.formdata.dataDays,
          rdv: this.formdata.dataRDV,
          centre: this.formdata.dataCentre.slug,
        };

        if (this.formdata.dataId !== null && this.formdata.dataId.length >= 0) {
          this.$store.dispatch("ModuleDoctor/updateItem", obj)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Action Réussie`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `Modification effectuée avec succès!`,
                },
              });

              this.initValues();
            })
            .catch((error) => {
              if (typeof error === "object") {
                for (let err in error) {
                  this.swalError("Erreur", err, "error");
                }
              } else {
                this.swalError("Erreur", error, "error");
              }
            });
        } else {
          delete obj.slug;
          this.$store
            .dispatch("ModuleDoctor/save", obj)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Action Réussie`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `Enregistrement effectué avec succès!`,
                },
              });
              this.initValues();
            })
            .catch((error) => {
              if (typeof error === "object") {
                for (let err in error) {
                  this.swalError("Erreur", err, "error");
                }
              } else {
                this.swalError("Erreur", error, "error");
                
              }
            });
        }
      }
    },
    buildRequiredMessage(field) {
      return `Le champ ${field} est obligatoire`;
    },
  },
};
</script>
