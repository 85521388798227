import axios from '@/libs/axios.js'

let accessToken = localStorage.getItem('accessToken');
let path = '/docteur'

export default {
  addItem ({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post(path + '/save', item, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      }).then((response) => {
        console.log(reponse)
        commit('ADD_ITEM', Object.assign(response.data.data, {slug: response.data.data.slug}));
        resolve(response.data.data)
      }).catch((error) => {
        if (error.response) {
          let data = error.response.data;

          reject(data.data)
        } else {
          reject(error)
        }
      })
    })
  },
  save ({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post(path + '/save', item, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      }).then((response) => {
          commit('ADD_ITEM', Object.assign(response.data.data, {slug: response.data.data.slug}));
          resolve(response.data.data)
        }).catch((error) => {
          if (error.response) {
            let data = error.response.data;

            reject(data.data)
          } else {
            reject(error)
          }
        })
    })
  },
  fetchData ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(path + '/select', {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        }
      })
        .then((response) => {
          commit('SET_LIST', response.data.data);
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchCentre ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(path + '/select/centre', {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      })
        .then((response) => {
          commit('SET_CENTRES', response.data.data);
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateItem ({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.put(path + '/update', item, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      }).then((response) => {
          commit('UPDATE_ITEM', response.data.data);
          resolve(response.data.data)
        }).catch((error) => {
          if (error.response) {
            let data = error.response.data;

            reject(data.data)
          } else {
            reject(error)
          }
        })
    })
  },
  removeItem ({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.delete(path + '/archive/data/' + itemId, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      }).then((response) => {
          commit('REMOVE_ITEM', itemId);
          resolve(response)
      }).catch((error) => {
        if (error.response) {
          let data = error.response.data;

          reject(data.data)
        } else {
          reject(error)
        }
      })
    })
  },
  shredItem ({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.delete(path + '/delete/data/' + itemId, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      }).then((response) => {
          commit('REMOVE_ITEM', itemId);
          resolve(response)
      }).catch((error) => {
        if (error.response) {
          let data = error.response.data;

          reject(data.data)
        } else {
          reject(error)
        }
      })
    })
  },
  fetchItem (context, slug) {
    return new Promise((resolve, reject) => {
      axios.get(`/docteur/select/data/${slug}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
}
